<template>
  <v-container>
    <v-card>
      <v-btn
        color="success"
        fab
        absolute
        small
        top
        right
        @click="addDialog = !addDialog"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :items-per-page="15"
        :search="search"
      >
        <template v-slot:item.description="props">
          <v-edit-dialog
            :return-value.sync="props.item.description"
            @save="save(props.item)"
          >
            {{ props.item.description }}
            <template v-slot:input>
              <v-text-field
                class="pt-4"
                v-model="props.item.description"
                label="Beschreibung"
                outlined
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.id="{ item }">
          <small>{{ item.id }}</small>
        </template>
        <template v-slot:item.count="{ item }">
          <v-chip small>{{ item.count }}</v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="addDialog" max-width="400px" width="100%" persistent>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="success" class="mb-2">
            <v-toolbar-title>Modell hinzufügen</v-toolbar-title>
            <v-spacer />
            <v-btn @click="addDialog = false" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-text-field
            v-model="addDeviceType"
            label="Beschreibung"
            outlined
            focus
          ></v-text-field>

          <small class="grey--text"></small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="add" color="success"
            ><v-icon left>mdi-content-save</v-icon> Hinzufügen</v-btn
          >
          <v-btn text @click="addDialog = false"
            ><v-icon left>mdi-close</v-icon> Abbrechen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'InventoryDeviceTypes',
  props: ['search'],
  data: () => ({
    adding: false,
    addDeviceType: '',
    loading: false,
    items: [],
    addDialog: false,
    headers: [
      { text: 'id', value: 'id' },
      { text: 'Beschreibung', value: 'description' },
      { text: '#devices', value: 'count', align: 'end' },
      { text: '', value: 'actions', sortable: false },
    ],
  }),
  methods: {
    async add() {
      if (this.addDeviceType) {
        this.adding = true;
        await this.apiPost({
          resource: 'inventory/devicetype',
          data: { description: this.addDeviceType },
        });
        this.adding = false;
        this.addDialog = false;
        this.addDeviceType = '';
        this.$root.showSuccess('erfolgreich hinzugefügt');
        await this.getData();
      }
    },
    async save(deviceType) {
      await this.apiPut({ resource: 'inventory/devicetype', data: deviceType });
      this.$root.showSuccess('erfolgreich gespeichert');
    },
    async getData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: 'inventory/devicetype',
        query: 'count',
      });
      this.loading = false;
    },
  },
  async created() {
    this.getData();
  },
};
</script>
